import classNames from "classnames";
import delay from "delay";
import React from "react";
import { render } from "react-dom";
import { TailSpin } from 'react-loader-spinner'
import './LoadingScreen.css'
import LABLogo from '../assets/img/truename_logo.png'

export default class LoadingScreen extends React.Component {
  state = {
    displayState: 0
  }

  componentDidUpdate(oldProps) {
    if (this.props.active == oldProps.active) return
    if (this.props.active) {
      this.setState({ displayState: 1 }, async () => {
        await delay(10)
        this.setState({ displayState: 2 })
      })
    } else {
      this.setState({ displayState: 1 }, async () => {
        await delay(500)
        this.setState({ displayState: 0 })
      })
    }
  }

  componentDidMount() {
    const { active } = this.props
    console.log('Loader mount: ', active)
    if (active) {
      this.setState({ displayState: 2 })
    } else {
      this.setState({ displayState: 0 })
    }
  }

  render() {
    const { message } = this.props
    const { displayState } = this.state

    let cls = classNames({
      "loader": true,
      "interim": (displayState == 1),
      "visible": (displayState == 2)
    })

    return (
      <div className={cls}>
        <img src={LABLogo}/>
        { message && <h4>{message}</h4>}
        <TailSpin color="#eb3224" height={40} width={40} />
      </div>
    )
  }
}