import './App.css';
import 'react-dropdown/style.css';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import MintLanding from './routes/MintLanding';
import Mint from './routes/Mint';
import { changeLanguage } from './i18n'
import classNames from 'classnames';
import delay from 'delay';

export default class App extends React.Component {
  state = { 
    language: window.localStorage.getItem("language") || "en",
    hidden: false
  }

  onChangeLanguage = (lang) => async () => {
    this.setState({ hidden: true })
    await delay(300)    
    console.log('Language: ', lang)
    this.setState({ language: lang }, async () => {
      await changeLanguage(lang)
      window.localStorage.setItem("language", lang)
      this.setState({ hidden: false })
    })    
  }

  getOppositeLanguage = (l) => {
    if (l == 'en') return 'de'
    return 'en'
  }
  render() {
    const { hidden } = this.state
    let languageButton = this.getOppositeLanguage(this.state.language)
    let allContainerClassnames = classNames({ "all-container": true, hidden: hidden })
    return (
      <>
      <div className="language-container">
        <div className="language-button" onClick={this.onChangeLanguage(languageButton)}>{languageButton.toUpperCase()}</div>
      </div>
      <div className={allContainerClassnames}>
        <Routes>
          <Route path="/" element={<MintLanding/>}/>
          <Route path="/mint/:uid" element={<Mint/>}/>
          <Route path="*" element={<MintLanding/>}/>      
        </Routes>
      </div>
     </>
    );
  }
}