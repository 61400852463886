import 'react-dropdown/style.css';
import React from 'react';
import { withRouter } from '../components/withRouter';
import LABLogo from '../assets/img/lab-logo-wavy.png'
import KukaiLogo from '../assets/img/kukai-logo.svg'
import { SERVER_ADDRESS, SERVER_ENDPOINT_MINT_REQUEST, SERVER_ENDPOINT_VIEW_REQUEST, NFT_STATE, READY_TO_DISPLAY_STATE, TZKT_URL_PREFIX, OBJKT_URL_PREFIX, OBJKT_COLLECTION_NAME, IS_DEVELOPMENT } from '../env';
import { Link } from 'react-router-dom';
import { KukaiEmbed, Networks } from 'kukai-embed';
import LoadingScreen from '../components/LoadingScreen';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BackgroundImage from '../components/Background';
import classNames from 'classnames';
import { DAppClient, BeaconEvent } from "@airgap/beacon-sdk"
// import { TezosToolkit } from '@taquito/taquito';
// import { BeaconWallet } from '@taquito/beacon-wallet';
import delay from 'delay';
import { withTranslation } from 'react-i18next';

const FAKE_LOADING_TIME = 1500

class Mint extends React.Component {
  state = {
    loading: true,
    loadingMessage: "Please hold while we retrieve your TRUE NAME...",
    fakeLoading: true,
    error: null,
    user: null,
    mintRequestSuccessful: true,
    walletId: "",
    mintNowState: 0,
    withKukai: false
  }
  async componentDidMount() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
    const { uid } = this.props.params

    let timeout = setTimeout(() => { this.setState({ fakeLoading: false })}, FAKE_LOADING_TIME)
      
    let { user, error } = await this.fetchUser(uid)

    // error = false
    // user = { uid: "12345", generatedName: 'Steve the Light', nftState: NFT_STATE.Viewed, readyToDisplayState: READY_TO_DISPLAY_STATE.Ready, name: 'Steve', nftImageUrl: '/placeholder.png' }

    if (error) {
      this.setState({ loading: false, error: error }, () => {
        clearTimeout(timeout)
        setTimeout(() => {
          this.props.navigate('/?userError=true', {})
        }, FAKE_LOADING_TIME * 0.6)
      })
    } else {
      // console.log('Done: ', user)
      this.setState({ loading: false, user: user })
    }    
  }

  async fetchUser(uid) {
    let response, res
    try {
      response = await fetch(
        SERVER_ENDPOINT_VIEW_REQUEST, 
        { 
          body: JSON.stringify({ uid: uid }), 
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      res = await response.json()
    } catch (e) {
      console.error(e)
      return { error: e }
    }

    // console.log('Response: ', res)

    if (res && res.user) {
      return { user: res.user, error: null }
    } else {
      return { error: "Could not find your code, please try again..." }
    }
  }

  shortenWalletId = (walletId) => {
    if (!walletId) return ""
    if (walletId.length < 8) return walletId
    let l = walletId.length
    return `${walletId[0]}${walletId[1]}${walletId[2]}${walletId[3]}${walletId[4]}${walletId[5]}...${walletId[l - 4]}${walletId[l - 3]}${walletId[l - 2]}${walletId[l - 1]}`
  }

  onKukaiClick = async () => {
    if (this.state.mintNowState > 0) return

    await new Promise((res, rej) => {
      this.setState({ loading: true, loadingMessage: "Connecting to Kukai Wallet..." }, res)
    })

    try {
      // construct the embed instance using mainnet (also the default value)
      let network = IS_DEVELOPMENT ? Networks.ghostnet : Networks.mainnet
      // console.log('Initializing Kukai for network: ', network)
      this.kukaiEmbed = new KukaiEmbed({ net: network });
      // wait for the embedded Kukai instance to be initialized
      await this.kukaiEmbed.init();
      await this.kukaiEmbed.logout();
    } catch (e) {
      console.log(e)
    }

    await new Promise((res, rej) => {
      this.setState({ loading: false }, res)
    })

    const userInfo = await this.kukaiEmbed.login()
    // console.log('User info: ', userInfo)
    this.setState({ 
      walletId: userInfo.pkh,
      mintNowState: 1
    }, async () => {
      await delay(50)
      this.setState({ mintNowState: 2, withKukai: true })
    })
  }

  onBeaconClick = async () => {
    if (this.state.mintNowState > 0) return

    this.setState({ loading: true, fakeLoading: true, loadingMessage: "Connecting to Beacon..." })
    setTimeout(() => { this.setState({ fakeLoading: false })}, FAKE_LOADING_TIME)
    setTimeout(() => { this.setState({ loading: false })}, 3000)

    let userAddress

    let dAppClient
    if (this.dAppClient) dAppClient = this.dAppClient
    else {
      dAppClient = new DAppClient({ name: "TRUE NAME" });
      this.dAppClient = dAppClient
    }

    const activeAccount = await dAppClient.getActiveAccount();
    console.log('active account? ', activeAccount)
    if (activeAccount) {
      // User already has account connected, everything is ready
      // You can now do an operation request, sign request, or send another permission request to switch wallet
      // console.log("Already connected:", activeAccount.address);
      userAddress = activeAccount.address
      this.setState({ loading: false, walletId: userAddress, mintNowState: 1 }, async () => {
        await delay(50)
        this.setState({ mintNowState: 2 })
      })  
    } else {
      // console.log('Requesting permissions')
      const permissions = await dAppClient.requestPermissions();
      dAppClient.subscribeToEvent("PERMISSION_REQUEST_SENT", () => {
        // console.log('request sent')
      })
      dAppClient.subscribeToEvent("PERMISSION_REQUEST_SUCCESS", () => {
        // console.log('request success')
      })
      dAppClient.subscribeToEvent("PERMISSION_REQUEST_ERROR", () => {
        // console.log('request error')
      })
      dAppClient.subscribeToEvent("HIDE_UI", () => {
        // console.log('hide ui')
      })
      dAppClient.subscribeToEvent(BeaconEvent.CHANNEL_CLOSED, () => {
        // console.log('channel')
      })
      // console.log("New connection:", permissions.address);
      userAddress = permissions.address
      console.log('P: ', permissions)
    }
    
    // console.log("Address: ", userAddress)
    this.setState({ loading: false, walletId: userAddress, mintNowState: 1 }, async () => {
      await delay(50)
      this.setState({ mintNowState: 2 })
    })
  }

  onMint = async () => {
    const { user, walletId } = this.state  

    this.setState({ loading: true, fakeLoading: true, loadingMessage: "Communicating with the Tezos network..." })
    setTimeout(() => { this.setState({ fakeLoading: false })}, FAKE_LOADING_TIME)

    let response, res
    try {
      response = await fetch(
        SERVER_ENDPOINT_MINT_REQUEST, 
        { 
          body: JSON.stringify({ uid: user.uid, walletId: walletId }), 
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Bypass-Tunnel-Reminder': 'true',
            'ngrok-skip-browser-warning': 'true'
          },
        }
      )
      
      res = await response.json()
      // console.log('Got response: ', res)

      if (res && res.success && res.user) {
        this.setState({ loading: false, user: res.user, mintRequestSuccessful: true, mintMessage: '' })
      } else {
        this.setState({ loading: false, user: res.user, mintRequestSuccessful: false, mintMessage: res.message })
      }
    } catch (e) {
      this.setState({ loading: false, mintRequestSuccessful: false, mintMessage: "Caught exception in communicating with minting endpoint..." })
    }
  }

  componentDidUpdate() {
    const { uid } = this.props.params
    const { user } = this.state
    if (!user) return

    if (user.nftState == NFT_STATE.Minting) {
      setTimeout(async () => {
        let { user } = await this.fetchUser(uid)
        this.setState({ user })
      }, 10000)
    }
  }

  componentWillUnmount() {
    if (this.kukaiEmbed) {
      this.kukaiEmbed.logout()
      document.getElementById("kukai-iframe").remove()
      delete this.kukaiEmbed
    }
  }

  render() {
    let { loading, fakeLoading, user, error, walletId, loadingMessage, isMinting, mintRequestSuccessful, mintMessage, mintNowState, withKukai } = this.state
    const { t } = this.props

    let isLoading = loading || fakeLoading
    let tzktUrl, objktUrl
    if (user) {
      tzktUrl = `${TZKT_URL_PREFIX}/${user.nftContractAddress}/tokens/${user.nftTokenId}`
      objktUrl = `${OBJKT_URL_PREFIX}/${OBJKT_COLLECTION_NAME}/${user.nftTokenId}`
    }

    // if (user)
    //   user.nftState = NFT_STATE.Minted
    // walletId = "tzfiuwef4uhf4hu3483uo3243324"    
    // mintNowState = 2

    let hasBackground = (user && user.readyToDisplayState == READY_TO_DISPLAY_STATE.NotReady)

    let mintNowClassnames = classNames({ 
      "mint-now-container": true,
      "interim": (mintNowState == 1),
      "visible": (mintNowState == 2),
    })

    let walletButtonClassnames = classNames({
      "wallet-button": true,
      "wallet-button-connect": true,
      "interactive": (mintNowState == 0),
      "disabled": (mintNowState > 0)
    })

    // if (user) user.nftState = NFT_STATE.Minting

    return (
      <div className="app-container">
        { hasBackground && <BackgroundImage/> }

        <div className="content">          
          <Header/>
          <LoadingScreen message={ t("loadingscreen_loading") } active={isLoading}/>

          { user &&
            <div className='nft-container'>
              { (user.readyToDisplayState == READY_TO_DISPLAY_STATE.NotReady) && 
                <div className="mint-state-container">
                  <div className='nft-status red'>
                    <div>
                      <span className="nft-status-text-container">{ t("notready_label") }</span>
                    </div>
                  </div>
                  <div className='section-container not-ready-message-container'>
                    <div>
                      { t("notready_hello") } { user.name },<br/>
                      <div dangerouslySetInnerHTML={{ __html: t("notready_message") }}></div>
                    </div>
                  </div>
                </div>
              }          

              { (user.readyToDisplayState == READY_TO_DISPLAY_STATE.Ready) && 
                <div className="mint-state-container">

                  {/* { !mintRequestSuccessful &&
                    <h4 style={{color: 'red', maxWidth: '500px'}}>{mintMessage}</h4>
                  } */}

                  <div className='section-container nft-image-container'>
                    <div className='nft-image-card'>
                      {/* <div className='nft-card-title'>
                        <h3>Your true name:<br/>{user.generatedName}</h3>
                      </div> */}
                      <img src={`${user.nftImageUrl}`}/>                          
                    </div>
                  </div>

                  { user.nftState == NFT_STATE.Minted && 
                    <div>
                      <div className='nft-status green'>
                        <div> { t("minted_success") } </div>
                      </div>
                      <div className='nft-confirmation-details'>
                        <a href={objktUrl} target="_blank">{ t("minted_viewObjkt") }</a>
                        <br/>
                        { withKukai &&
                          <>
                            <a href="https://kukai.app" target="_blank">{ t("minted_viewKukai") }</a> 
                            <br/> 
                          </>
                        }
                        <a href={tzktUrl} target="_blank">{ t("minted_viewTzkt") }</a>
                      </div>
                    </div>
                  }

                  { user.nftState == NFT_STATE.Minting && 
                    <div>
                      <div className='nft-status yellow'>
                        <div> { t("minting_progress") } </div>
                      </div>
                      <div className='nft-confirmation-details'>
                        <div dangerouslySetInnerHTML={{ __html: t("minting_progressExplanation") }}></div>
                      </div>
                    </div>
                  }

                  { user.nftState == NFT_STATE.MintRequestError && 
                    <div>
                      <div className='nft-status red'>
                        <div dangerouslySetInnerHTML={{ __html: t("error_message") }}></div>
                      </div>
                    </div>
                  }

                  { (user.nftState == NFT_STATE.Viewed || user.nftState == NFT_STATE.MintRequestError) && 
                    <div className="section-container mint-container">

                      {/* <div className='instructions-step underline'>
                        { t("ready_stepOne") }
                      </div> */}

                      { (mintNowState < 2) && 
                        <>
                          <div className='instructions'>
                            { t("ready_claim") } <br className="no-mobile"/><br/>
                          </div>
                          <div className="mint-buttons-container">
                            <div className={walletButtonClassnames} onClick={this.onKukaiClick}>
                              <span>{ t("ready_createWallet") }</span>
                            </div>

                            <div className={walletButtonClassnames} onClick={this.onBeaconClick}>
                              <span>{ t("ready_connectWallet") }</span>
                            </div>
                          </div>
                        </>
                      }

                      {/* <div className='kukai-container interactive' onClick={this.onKukaiClick}>
                        <div className='kukai-badge'>
                          <img className="kukai-logo" src={KukaiLogo}/>
                        </div>
                      </div>

                      <div className='wallet-input-container'>
                        <p className="instructions">Enter your Kukai wallet address below:</p>
                        <input className="select wallet-input" type="text" value={walletId} onChange={(e) => this.setState({ walletId: e.target.value })}/>
                      </div>

                      <div className='mint-arrow'>
                      </div> */}

                      <div className={mintNowClassnames}>
                        {/* <div className="scroll-down"></div> */}

                        {/* <div className='instructions-step underline'>
                        { t("ready_stepTwo") }
                        </div> */}

                        <div className="wallet-address-container">
                          <div className="connected-text"> { t("ready_connectedAs") } </div>
                          <div className="connected-text-wallet"> { this.shortenWalletId(walletId) } </div>
                        </div>

                        <div className='mint-button-container'>
                          <div className='wallet-button interactive' onClick={this.onMint}>
                            { isMinting ? t("ready_mintingInProgress") : t("ready_mintNow") }
                          </div>
                        </div>

                      </div>

                    </div>
                  }

                </div>
              }              


            </div>
          }

          { (!isLoading) && error &&
            <div className='text-instructions'>
              <h3>{ error }</h3>
            </div>
          }

          <Footer inverted={hasBackground}/>
        </div>        
      </div>
    );
  }
}

export default withTranslation()(withRouter(Mint))