import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const withRouter = WrappedComponent => props => {
  const params = useParams();
  const navigate = useNavigate()
  const location = useLocation()  
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
      location={location}
      // etc...
    />
  );
};