import 'react-dropdown/style.css';
import React from 'react';
import './Background.css'
import BackgroundImage from '../assets/img/lab-bg-crop.png'

class Background extends React.Component {
  render() {
    return (
      <div className="background-image">
        <img src={BackgroundImage}/>
      </div>
    );
  }
}

export default Background