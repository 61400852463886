import 'react-dropdown/style.css';
import React from 'react';
import './Header.css'
import LABLogo from '../assets/img/truename_logo.png'

class Header extends React.Component {
  render() {
    return (
      <div className="logo-container interactive">
        <a href="/">
          <img className="logo-image" src={LABLogo}/>
        </a>
      </div>
    );
  }
}

export default Header